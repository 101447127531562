import React from "react";
import Layout from "../../Layout";
import SEO from "../../seo";
import "./style.scss";

export default function BusRoutes() {
  return (
    <Layout>
      <SEO
        title="Bus Routes | Chinmaya Vidyalaya Vasant Vihar"
        description="Find detailed information about bus routes and areas covered by Chinmaya Vidyalaya Vasant Vihar school transport. Note that transport is subject to seat availability."
      />
      <div className="busroutes">
        <h1 className="heading">Bus Routes</h1>

        <p>
          You can apply for bus facility if your residence area lies within the
          area covered by school transport. However, please note that the school
          transport will only be provided subject to available routes and seats.
          The school does not guarantee or commit to providing transport
          facilities.
        </p>

        <div className="busroutes__table">
          <div className="busroutes__table__row busroutes__table__row--heading">
            <div className="busroutes__table__col1">Bus Route</div>
            <div className="busroutes__table__col2">
              Areas Covered By School Buses
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 1</div>
            <div className="busroutes__table__col2">
              Jaunapur, Satbari, Chhatarpur DLF, Nanda Hospital
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 2</div>
            <div className="busroutes__table__col2">
              Ganga Hostel, JNU State Bank, Kendriya Vidyalaya JNU, JNU Akarshan
              Kendra, Purvanchal Hostel, JNU East Gate, IUAC, Ghitorni Metro,
              Sultanpur Metro, New Mangla Puri
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 3</div>
            <div className="busroutes__table__col2">
              Sai Baba Mandir, Tivoli Garden, Chattarpur Birla
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 4</div>
            <div className="busroutes__table__col2">
              Saket M Block, Pushp Vihar Sector 1 & 5, Sheikh Sarai, Khanpur Red
              Light, Saidulajab Main Road, Lado Sarai DDA Flat, PTS Colony, Ber
              Sarai Red Light, JNU IIT
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 5</div>
            <div className="busroutes__table__col2">
              Ashram Chowk, Jungpura (Bhogal Main Road), Lajpat Nagar Metro
              Station, Kotla Red Light, Ansal Plaza, Anand Lok, Father Agnel
              School, Hauz Khas Market, Green Park, Sukhmani Hospital Arjun
              Nagar, Deer Park, R.K. Puram Sector-4, Anupam Restaurant Munirka,
              Vasant Vihar Depot
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 6</div>
            <div className="busroutes__table__col2">
              Dhaula Kuan, Nanak Pura Gurudwara, Moti Bagh Under Fly-over,
              Nivedita Kunj, Sangam Cinema, Mohan Singh Market, R K Puram Sector
              5-6, Budh Vihar Munirka, Munirka Vihar, DDA Flat Munirka
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 7</div>
            <div className="busroutes__table__col2">
              E1 Vasant Kunj, E2 Vasant Kunj, C8, C9 Vasant Kunj, CNG Vasant
              Kunj, DPS Vasant Kunj
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 8</div>
            <div className="busroutes__table__col2">
              Vasant Kunj D6, B1, B2, B5, Masoodpur, Jal Board Vasant Kunj,
              Nelson Mandela Road (opp. Ambience Mall), Paschimabad, LIG Flat
              Vasant Kunj
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 9</div>
            <div className="busroutes__table__col2">
              C.R. Park Mela Ground, Chirag Delhi, Panchsheel Metro Station,
              Malviya Nagar T-Point, Malviya Nagar Maruti Showroom, Begumpur,
              Mother Child School, Adchini Red Light, Qutub Enclave, Indian Bank
              Katwaria Sarai, Vidyapeeth, Ber Sarai Red Light, JNU Main Gate
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 10</div>
            <div className="busroutes__table__col2">
              Radisson Hotel Under Flyover Mahipalpur, Brahmaputra House next to
              red light, Govt. School Mahipalpur, Mata Chowk, Oriental Bank
              Mahipalpur
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 11</div>
            <div className="busroutes__table__col2">
              Nangal Dewat Main Road, Mahipalpur Choti Red Light, Vasant Valley
              School, Vasant Kunj C1 & C3, D7 Vasant Kunj
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 12</div>
            <div className="busroutes__table__col2">
              Mehrauli Terminal, Chattarpur 100 ft road, Kanwar Singh Tanwar,
              Chattarpur Red Light, Yes Bank Chattarpur, Chattarpur Temple,
              Chattarpur Metro Station, Andheria Mod, B4, B5 Vasant Kunj, B-6
              Mother Dairy Vasant Kunj, Harijan Basti
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 13</div>
            <div className="busroutes__table__col2">
              Laxmi Bai Nagar, Sarojini Nagar Market, Sarojini Nagar Depot,
              Trauma Centre, Kamal Cinema, Greenfield School Safdarjung,
              Mohammadpur, Church Central School, R.K. Puram Sector-8 & 9 Main
              Road Only, Malai Mandir
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 14</div>
            <div className="busroutes__table__col2">
              Sector D2 & D3 Vasant Kunj, Sector A Vasant Kunj, Ambience Mall
              Vasant Kunj, Kishangarh Car Market, Fortis Hospital, Harpreet
              Ford, Kishangarh Church, Shiv Mandir Kishangarh, Kishangarh CNG
              Pump
            </div>
          </div>
        </div>

        <h3>
          Junior Route Timing and Areas Covered (Morning Route same as Senior
          Route)
        </h3>

        <div className="busroutes__table">
          <div className="busroutes__table__row busroutes__table__row--heading">
            <div className="busroutes__table__col1">Bus Route</div>
            <div className="busroutes__table__col2">
              Areas Covered By School Buses
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 1</div>
            <div className="busroutes__table__col2">
              Vasant Kunj B10, Kishangarh, Harpreet Ford, Fortis Hospital,
              Kishangarh Car Market, D-3, Sector A Vasant Kunj, Chattarpur 100ft
              Road, Tivoli, Satbari, Nanda Hospital, Chattarpur DLF, Ghitorni
              Metro Station, Sultanpur Metro Station
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 2</div>
            <div className="busroutes__table__col2">
              D-6, Vasant Kunj, Nagal Dewat, E-1, E-2, Vasant Kunj, Mahipalpur,
              Mata Chowk, Brahmaputra House, Mahipalpur Choti Red Light,
              Radisson Hotel
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 3</div>
            <div className="busroutes__table__col2">
              Budh Vihar Munirka, Ber Sarai Red Light, Katwariya Sarai, Adchini
              Red Light, Aurobindo College, Lado Sarai DDA Flat, Saket,
              Saidulajab Main Road, Khanpur Main Road
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 4</div>
            <div className="busroutes__table__col2">
              JNU SBI, Purvanchal, Munirka DDA Flat, Kendriya Vidyalaya, Mother
              Dairy Munirka, Restaurant, R.K. Puram Sector-1, 3, 4, 5, 8 & 9
              Main Road Only, Sangam Cinema, Moti Bagh, Safdarjung Enclave Deer
              Park, Arjun Nagar, Malai Mandir
            </div>
          </div>
        </div>
        <br />
        <div>
          <strong>Note: </strong>
          <ul style={{ marginLeft: "20px" }}>
            <li>
              Senior Route Buses ply in the Morning (for Nur to XII) and
              Afternoon (for classes I to XII at 2.15 pm).
            </li>
            <li>Junior Route Buses (Only for Nur & Prep) ply at 11.30 am.</li>
          </ul>
        </div>
        <br />
        <p>
          <strong>
            For any query, please contact: <br />
            Mr. Satender Bhandari - 8130480044 <br />
            Mr. Ashwani Rana - 9810878352
          </strong>
        </p>
      </div>
    </Layout>
  );
}
